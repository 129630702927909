import Fuse from 'fuse.js';
import data from '@/map/pois.json';

export const startSearch = async (search: string) => {
  const options = {
    includeScore: true,
    threshold: 300,
    location: 10,
    distance: 300,
    minMatchCharLength: 2,
    keys: ['place_name', 'place_type']
  };

  const fuse = new Fuse(data, options);

  let results = fuse.search(search).map(result => result.item);
  //if (process.env.VUE_APP_BUILD !== 'VX')
    results = results.filter(
      (result: any) => result.tags?.length && result.tags.includes(process.env.VUE_APP_BUILD)
    );
  return results;
};
