import * as turf from '@turf/turf';

export const getGeometry = (item: any) => {
  //   if (!item.lat || !item.long) return;

  return item.map((i: any) => {
    return {
      ...i,
      ...turf.point([i.lng, i.lat])
    };
  });
};
