import * as Sentry from '@sentry/vue';

interface SearchPlacesOptions {
  search: string;
  types?: Array<
    | 'country'
    | 'region'
    | 'postcode'
    | 'district'
    | 'place'
    | 'locality'
    | 'neighborhood'
    | 'address'
    | 'poi'
  >;
  language?: string;
  limit?: number;
  proximity?: Array<number>;
  fuzzyMatch?: boolean;
  country?: Array<string>;
  bbox?: Array<number>;
}
const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/`;
const andIsEqual = (label: string, content: string) => `&${label}=${content}`;

// More infos can be found : https://docs.mapbox.com/api/search/geocoding/#forward-geocoding
export const searchPlaces = async (options: SearchPlacesOptions): Promise<Array<any> | string> => {
  if (!options.search && options.search.length) return [];
  const types =
    options.types && Array.isArray(options.types) ? andIsEqual('types', options.types.join()) : '';
  const country =
    options.country && Array.isArray(options.country)
      ? andIsEqual('country', options.country.join())
      : '';
  const bbox =
    options.country && Array.isArray(options.bbox)
      ? andIsEqual('country', options.bbox.join())
      : '';
  const language = options.language ? andIsEqual('language', options.language) : '';
  const fuzzyMatch = options.fuzzyMatch
    ? andIsEqual('fuzzyMatch', options.fuzzyMatch.toString())
    : '';
  const limit = options.limit ? andIsEqual('limit', options.limit.toString()) : '';
  const search = encodeURI(options.search);
  const computedURL = `${url}${search}.json?access_token=${process.env.VUE_APP_MAPBOX_KEY}${types}${country}${bbox}${language}${fuzzyMatch}${limit}`;
  try {
    return (await (await fetch(computedURL)).json()).features;
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};
